body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  /* CyTech Primary Colors */
  --orange: #f99d1b;
  --green: #67ad45;
  --blue: #109eda;
  --gray: #5b656d;
  --black: #222222;

  /* Other Colors */
  --yellow: #bebe00;

  /* Product Colors */
  --uncas-color: #5b656d;
  --mahpiya-color: #d359c7;
  --sequoia: #16762c;
  --tipi: #b29b58;
  --hawkeye: #fac917;
  --okhophe: #af764a;
  --aquila: #e4b366;
  --tomahawk: #a25023;

  /* Primary-Darker Color */
  --darker-orange: #8b5303;
  --green-darker: #539731;
  --blue-darker: #0285bd;
  --gray-darker: #46515a;
  --more-gray-darker: #2a3136;

  /* Body Background */
  --section-bg-color: #f6f6f6;

  /* Buttons Color */
  --btn-bng-color-orange: #f99d1b;
  --btn-bng-color-orange-hover: #d17500;
  --btn-bng-color-blue: #11a0db;
  --btn-bng-color-blue-hover: #008cc7;

  --btn-uncas-color: #5b656d;
  --btn-uncas-color-hover: #47545e;

  /* Tooltip Color */
  --tooltip-bg-color: #5c7d8b;
  --tooltip-font-green: #00ff00;
  --tooltip-font-yellow-green: #9cd401;
  --tooltip-font-yellow: #eeff00;
  --tooltip-font-red: #ff5e00;

  /* Link Color */
  --link-color-blue: #004563;
  --link-color-blue-hover: #0d668b;

  /* Font Size */
  --h1-size: 2em;
  --h2-size: 1.5em;
  --h3-size: 1.17em;
  --h4-size: 1em;
  --h5-size: 0.83em;
  --h6-size: 0.67em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: black;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
}
/* .cursor-pointer{
  cursor: pointer;
}
.cell-wrap{
  white-space: pre-wrap !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding-top: 1em;
  padding-bottom: 1em; 
} 
*/
.MuiDataGrid-cellContent{
  cursor: pointer;
  padding-top: .5em;
  padding-bottom: .5em;
  white-space: pre-wrap !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.m-1em{
  margin-top: .7em;
  margin-bottom: .7em;
}